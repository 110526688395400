<template>
  <div class="main-wrap" style="padding: 10px">
    <div class="bar-wrap">
      <el-input
        v-model="search_text"
        class="search-bar"
        placeholder="請輸入通知標題"
        :prefix-icon="Search"
        clearable
        @keyup.enter="handleSearch"
        @clear="handleSearch"
      />
      <el-button class="create-button" type="primary" @click="openDialog('notificationSetting', { action: 'create' })">
        新增
      </el-button>
    </div>
    <el-table
      ref="listTable"
      :data="getNotificationList"
      height="100%"
      class="table-basic"
      header-cell-class-name="cus-table-header"
      row-class-name="cus-table-row"
      empty-text="尚無資料"
    >
      <el-table-column prop="title" label="標題" :show-overflow-tooltip="true" />
      <el-table-column prop="available_shop_type" label="門市" width="200">
        <template #default="scope">
          <span v-if="scope.row.available_shop_type === 1">全門市</span>
          <span v-else-if="scope.row.available_shop_type === 2">
            {{ getAreaText(scope.row.available_shop_value_array) }}
          </span>
          <span
            v-else-if="scope.row.available_shop_type === 3"
            class="download-text"
            @click="downloadFile(scope.row.available_shop_file_url)"
          >
            指定門市（csv）
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="send_datetime" label="通知日期" width="160">
        <template #default="scope">
          <span>{{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.send_datetime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="read_count" label="讀取狀況（已讀數/發送數）" width="250">
        <template #default="scope">
          <div class="d-flex">
            <p style="width: 50px">{{ scope.row.read_count }}/{{ scope.row.send_count }}</p>
            <span class="download-text">下載CSV</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="功能" fixed="right" align="center" width="110">
        <template #default="scope">
          <el-dropdown class="action">
            <span class="el-dropdown-link">
              操作
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="openDialog('notificationSetting', { action: 'edit', init: scope.row, index: scope.$index })"
                >
                  編輯
                </el-dropdown-item>
                <el-dropdown-item @click="handleDeleteNotification(scope.row)">刪除</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:currentPage="currentPage"
      :page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="getPageTotal"
      @current-change="handleCurrentChange"
    />
  </div>

  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { getCurrentInstance, defineAsyncComponent, ref, watch } from 'vue';
import { useDialog } from '@/utils/dialog';
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { formatDateTime } from '@/utils/datetime';
import { downloadFile } from '@/utils/download';
import { getAreaText } from '@/utils/define';
import { Search } from '@element-plus/icons-vue';
export default {
  name: 'notification',
  components: {
    NotificationDialog: defineAsyncComponent(() => import('@/components/information/NotificationDialog.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const { dialogTemplate, openDialog, closeDialog } = useDialog();
    const route = useRoute();
    const router = useRouter();

    //設定參數取得資料
    const search_text = ref('');
    const listTable = ref(null);
    const currentPage = ref(1);
    const { getNotificationList, getPageSize, getPageTotal } = useGetters('notification', [
      'getNotificationList',
      'getPageSize',
      'getPageTotal',
    ]);
    const { doGetNotificationList, doGetNotificationSummary } = useActions('notification', [
      'doGetNotificationList',
      'doGetNotificationSummary',
    ]);

    //初始化
    const query = { page: 1 };
    router.push({ query, replace: true }).then(() => {
      currentPage.value = Number(route.query.page);
      refreshList('init');
    });

    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };

    //搜尋
    const handleSearch = () => {
      refreshList('search');
    };

    //更新list
    watch(
      () => route.query.page,
      async () => {
        if (route.query.page) {
          currentPage.value = Number(route.query.page);
          refreshList('page');
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );
    const refreshList = async (action) => {
      if (action === 'create') {
        currentPage.value = 1;
        const query = { page: currentPage.value };
        router.push({ query });
        search_text.value = '';
      } else if (action === 'search') {
        currentPage.value = 1;
        const query = { page: currentPage.value };
        router.push({ query });
      }
      if (action !== 'edit' && action !== 'page') await doGetNotificationSummary(search_text.value);
      if (currentPage.value > Math.ceil(getPageTotal.value / getPageSize.value))
        currentPage.value = Math.max(Math.ceil(getPageTotal.value / getPageSize.value), 1);
      await doGetNotificationList({ title: search_text.value, start: (currentPage.value - 1) * getPageSize.value });
      closeDialog();
    };

    //刪除訊息
    const doDeleteNotification = useActions('notification', ['doDeleteNotification']);
    const handleDeleteNotification = (item) => {
      proxy
        .$confirm(`確定要刪除－${item.title}？`, '確認刪除', {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'el-button--danger',
        })
        .then(async () => {
          await doDeleteNotification(item.id);
          refreshList('delete');
          proxy.$message({
            type: 'success',
            message: '刪除成功',
          });
        });
    };

    //離開清空vuex
    const RESET = useMutations('notification', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/information/notification')) RESET();
      }
    );
    return {
      Search,
      getAreaText,
      formatDateTime,
      downloadFile,
      dialogTemplate,
      openDialog,
      search_text,
      listTable,
      currentPage,
      getPageSize,
      getPageTotal,
      getNotificationList,
      handleCurrentChange,
      handleSearch,
      refreshList,
      handleDeleteNotification,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$searchbar-height: 70px;
$pagination-height: 50px;
.main-wrap {
  padding-top: $searchbar-height !important;
  padding-bottom: $pagination-height !important;
}

.bar-wrap {
  position: absolute;
  display: flex;
  top: calc($searchbar-height / 2);
  right: $padding-unit;
  transform: translateY(-50%);
}
.create-button {
  width: 110px;
  margin-left: 20px;
}
.search-bar {
  width: 200px;
}
.table-basic {
  width: 100%;
  border-top: 1px solid $el-border-color-lighter;
}
.action {
  cursor: pointer;
  width: 110px;
  @include flex-center-center;
}
.el-dropdown-link {
  color: $color-primary;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
</style>
